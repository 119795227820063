import client from 'libs/HttpClient';
class NotificationsService {
  static getNotificationsData() {
    return client.get('notifications/get');
  }
  static notificationList(request) {
    const page_no = request.queryKey[1];
    return client.get(`notifications/get-notifications?page=${page_no}`, request);
  }
  static notificationCount() {
    return client.get('notifications/notification-count');
  }
  static deleteNotification(request) {
    return client.post('notifications/remove-notification', request);
  }
  static readNotification(request) {
    return client.post('notifications/read-notification', request);
  }
  static addNotification(request) {
    return client.post('notifications/add', request);
  }
}

export { NotificationsService };
