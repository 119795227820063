import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

const CustomSwitch = ({ checked, onChange }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      onColor="#091D48"
      offColor="#c1c1c1"
      checkedIcon={false}
      uncheckedIcon={false}
      handleDiameter={24}
      height={28}
      width={50}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      className="switch"
    />
  );
};
CustomSwitch.propTypes = {
  t: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default CustomSwitch;
