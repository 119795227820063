import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CONTACT_SETTING } from 'config';
import PropTypes from 'prop-types';

const ContactUsSetting = React.lazy(() => import('./ContactUsSettingPage'));

const ContactRoutes = ({ t }) => (
  <Routes>
    <Route exact path={CONTACT_SETTING} element={<ContactUsSetting t={t} />} />
    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);
ContactRoutes.propTypes = {
  t: PropTypes.func,
};
export default ContactRoutes;
