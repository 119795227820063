import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const { FaqCategoryService } = require('api');
const onDefaultError = (error) => {
  toast().error(error.message);
};
const useListFaqCategory = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['faq-category-list', params],
    () => FaqCategoryService.listFaqCategory({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.addFaqCategory, {
    onSuccess,
    onError,
  });
};

const useViewFaqCategory = (faq_category_id, onSuccess, onError = onDefaultError) => {
  return useQuery(
    'faq-category-view',
    () => FaqCategoryService.viewFaqCategory({ faq_category_id }),
    {
      onSuccess,
      onError,
    }
  );
};

const useUpdateFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.updateFaqCategory, {
    onSuccess,
    onError,
  });
};

const useStatusChangeFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.statusChangeFaqCategory, {
    onSuccess,
    onError,
  });
};

const useDeleteFaqCategory = (onSuccess, onError = onDefaultError) => {
  return useMutation(FaqCategoryService.deleteFaqCategory, {
    onSuccess,
    onError,
  });
};

export {
  useListFaqCategory,
  useAddFaqCategory,
  useViewFaqCategory,
  useUpdateFaqCategory,
  useStatusChangeFaqCategory,
  useDeleteFaqCategory,
};
