import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { AdminDashboardService } from 'api';

/**
 * Hooks for Authentication Process
 */
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useDashboardCount = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'notifications_data',
    () => {
      return AdminDashboardService.getDashboardData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useUsersData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['users_data', [params]],
    () => {
      return AdminDashboardService.getUserData({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useDashboardCount, useUsersData };
