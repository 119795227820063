import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { loggedUser, incrementNotificationCount } from 'store';
import { requestForToken, onMessageListener } from './firebase';
// import { useAddNotification } from 'hooks';

const Notification = () => {
  const dispatch = useDispatch();
  const checkLoggedInUser = useSelector(loggedUser);
  const [notification, setNotification] = useState({ title: '', body: '' });
  const notify = () => toast.info(<ToastDisplay />);
  const ToastDisplay = () => {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  useEffect(() => {
    if (checkLoggedInUser.isAdminLoggedIn) {
      // Request for token and then set a timeout for notifications
      requestForToken(dispatch);

      // Delay the notification check after login
      const notificationTimeout = setTimeout(() => {
        if (notification?.title) {
          notify();
        }
      }, 1500); // Adjust the timeout duration as needed

      // Cleanup timeout on unmount
      return () => clearTimeout(notificationTimeout);
    } else if (notification?.title) {
      notify();
    }
  }, [notification, checkLoggedInUser]);

  // const { mutate: doAddnotification } = useAddNotification(
  //   (res) => {
  //     console.log(res);
  //   },
  //   (err) => {
  //     toast.error(err.message);
  //   }
  // );

  onMessageListener()
    .then((payload) => {
      const { notification, data } = payload;
      console.log(notification, 'notification p', data, 'data');
      // doAddnotification({ notification, data });
      setNotification({ title: notification?.title, body: notification?.body });
      dispatch(incrementNotificationCount());
    })
    .catch((err) => console.log('failed: ', err));
};

export default Notification;
