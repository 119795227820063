// import libs
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {
  ChangePasswordPage,
  CmsRoutes,
  DashboardPage,
  EditProfilePage,
  EmailTemplateRoutes,
  FaqCategoryRoute,
  FaqRoute,
  FeatureRoute,
  ForgetPasswordPage,
  LoginPage,
  Notifications,
  OtpVerificationPage,
  ResetPasswordPage,
  SettingsRoutes,
  TeamMemberRoute,
  UserRoutes,
  TranslationRoutes,
} from 'pages';
import {
  DASHBOARD,
  FORGOT_PASSWORD,
  LOGIN,
  OTP_VERIFY,
  RESET_PASSWORD,
  SETTING,
  EDIT_PROFILE,
  CHANGE_PASSWORD,
  EMAIL_TEMPLATE,
  CMS,
  TRANSLATIONS,
  FAQ,
  FAQ_CATEGORY,
  USERS,
  NOTIFICATIONS,
  FEATURE,
  TEAM_MEMBER,
} from 'config';
import { useGetSettingData } from 'hooks';
import { settingData, addSetting } from 'store';
import { PageNotFound } from 'common';
import { CATEGORIES } from 'config';
import CategoryRoutes from 'pages/Category/CategoryRoutes';
import { ATTRIBUTES } from 'config';
import AttributesRoute from 'pages/Attributes/AttributesRoute';

const PagesRoutes = ({ t }) => {
  const dispatch = useDispatch();
  /**
   * This function will cal on page load, and will set data to redux store
   */
  useGetSettingData(({ data: general_data }) => {
    if (general_data) {
      const dataStore = {
        home_page_general_header_logo: general_data.home_page_general_header_logo,
        home_page_general_header_sub_logo: general_data.home_page_general_header_sub_logo,
        home_page_general_email_logo: general_data?.home_page_general_email_logo,
        home_page_general_favicon_logo: general_data?.home_page_general_favicon_logo,
        home_page_general_seo_title: general_data.home_page_general_seo_title,
        home_page_general_seo_description: general_data.home_page_general_seo_description,
        setting_get: false,
      };
      dispatch(addSetting(dataStore));
    }
  });
  /**
   * Getting data from redux store if already exist
   */
  const getSettingData = useSelector(settingData);
  return (
    <>
      <Helmet>
        <title>Boilerplate Admin</title>
        <meta name="og:title" content={getSettingData.home_page_general_seo_title} />
        <meta name="title" content={getSettingData.home_page_general_seo_title} />
        <meta name="description" content={getSettingData.home_page_general_seo_description} />
        <meta name="og:description" content={getSettingData.home_page_general_seo_description} />
        <link rel="icon" href={`${getSettingData.home_page_general_favicon_logo}`} />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route exact path="/" element={<LoginPage t={t} />} />
          <Route exact path={LOGIN} element={<LoginPage t={t} />} />
          <Route exact path={FORGOT_PASSWORD} element={<ForgetPasswordPage t={t} />} />
          <Route exact path={OTP_VERIFY} element={<OtpVerificationPage t={t} />} />
          <Route exact path={RESET_PASSWORD} element={<ResetPasswordPage t={t} />} />
        </Route>

        <Route element={<PrivateRoute />}>
          <Route exact path={DASHBOARD} element={<DashboardPage t={t} />} />
          <Route exact path={EDIT_PROFILE} element={<EditProfilePage t={t} />} />
          <Route exact path={CHANGE_PASSWORD} element={<ChangePasswordPage t={t} />} />
          <Route exact path={EMAIL_TEMPLATE + '/*'} element={<EmailTemplateRoutes t={t} />} />
          <Route exact path={CMS + '/*'} element={<CmsRoutes t={t} />} />
          <Route exact path={TRANSLATIONS + '/*'} element={<TranslationRoutes t={t} />} />
          <Route exact path={FAQ + '/*'} element={<FaqRoute t={t} />} />
          <Route exact path={FAQ_CATEGORY + '/*'} element={<FaqCategoryRoute t={t} />} />
          <Route exact path={USERS + '/*'} element={<UserRoutes t={t} />} />
          <Route exact path={NOTIFICATIONS} element={<Notifications t={t} />} />
          <Route exact path={FEATURE + '/*'} element={<FeatureRoute t={t} />} />
          <Route exact path={TEAM_MEMBER + '/*'} element={<TeamMemberRoute t={t} />} />
          <Route exact path={SETTING + '/*'} element={<SettingsRoutes t={t} />} />
          <Route exact path={CATEGORIES + '/*'} element={<CategoryRoutes t={t} />} />
          <Route exact path={ATTRIBUTES + '/*'} element={<AttributesRoute t={t} />} />
        </Route>
        <Route>
          <Route path="*" element={<PageNotFound t={t} />} />
        </Route>
      </Routes>
    </>
  );
};
PagesRoutes.propTypes = {
  t: PropTypes.func,
};
export default PagesRoutes;
