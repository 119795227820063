import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { NotificationsService } from 'api';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useGetNotifications = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    'notifications_data',
    () => {
      return NotificationsService.getNotificationsData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useNotificationList = ([page_no], onSuccess, onError = onDefaultError) => {
  return useQuery(['notification-list', page_no], NotificationsService.notificationList, {
    onSuccess,
    onError,
  });
};

const useNotificationCount = (onSuccess, onError = onDefaultError) => {
  return useQuery('notification-counter', NotificationsService.notificationCount, {
    onSuccess,
    onError,
  });
};

const useDeleteNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.deleteNotification, {
    onSuccess,
    onError,
  });
};

const useReadNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.readNotification, {
    onSuccess,
    onError,
  });
};
const useAddNotification = (onSuccess, onError = onDefaultError) => {
  return useMutation(NotificationsService.addNotification, {
    onSuccess,
    onError,
  });
};

export {
  useGetNotifications,
  useNotificationList,
  useDeleteNotification,
  useNotificationCount,
  useReadNotification,
  useAddNotification,
};
