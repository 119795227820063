import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FAQ_SETTING } from 'config';
import PropTypes from 'prop-types';

const FaqSetting = React.lazy(() => import('./FaqSettingPage'));

const FaqRoutes = ({ t }) => (
  <Routes>
    <Route exact path={FAQ_SETTING} element={<FaqSetting t={t} />} />;
    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);

FaqRoutes.propTypes = {
  t: PropTypes.func,
};
export default FaqRoutes;
