import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import { confirmAlert } from 'react-confirm-alert';

import 'assets/scss/page/_general_setting.scss';
import validationSchema from './GeneralSettingValidation';
import { useStoreSettingData, useGetSettingDataAlways, useGetAllSettingData } from 'hooks';
import { addSetting } from 'store';
import { AlertCommon, NavItemsCommon, TNButton, CommonHelmet } from 'common';
import { imagePreviewFormik } from 'helpers';
import { DASHBOARD, mailNotificationNavItems } from 'config';

const GeneralSetting = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboardRef = useRef();
  const faviconRef = useRef();
  const loginRef = useRef();
  const emailRef = useRef();
  const ogImageRef = useRef();
  const [getSettingData, setSettingData] = useState();

  useGetAllSettingData(
    ({ data: setting }) => {
      const dataStore = {
        home_page_general_header_logo: setting.home_page_general_header_logo || '',
        home_page_general_login_logo: setting.home_page_general_login_logo || '',
        home_page_general_email_logo: setting?.home_page_general_email_logo || '',
        home_page_general_favicon_logo: setting?.home_page_general_favicon_logo || '',
        home_page_general_seo_title: setting.home_page_general_seo_title || '',
        home_page_general_seo_description: setting.home_page_general_seo_description || '',
        home_page_general_email_address: setting.home_page_general_email_address || '',
        home_page_common_general_og_image: setting.home_page_common_general_og_image || '',
        setting_get: false,
      };
      setSettingData(dataStore);
      dispatch(addSetting(dataStore));
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDError,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    const dataStore = {
      home_page_general_header_logo: response.data.home_page_general_header_logo || '',
      home_page_general_login_logo: response.data.home_page_general_login_logo || '',
      home_page_general_email_logo: response.data?.home_page_general_email_logo || '',
      home_page_general_favicon_logo: response.data?.home_page_general_favicon_logo || '',
      home_page_general_seo_title: response.data.home_page_general_seo_title || '',
      home_page_general_seo_description: response.data.home_page_general_seo_description || '',
      home_page_general_email_address: response.data.home_page_general_email_address || '',
      home_page_common_general_og_image: response.data.home_page_common_general_og_image || '',
      setting_get: true,
    };
    dispatch(addSetting(dataStore));
    setTimeout(function () {
      navigate(DASHBOARD);
    }, 1500);
  });

  /**
   * !This block will call on click cancel
   */
  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close p-0"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(DASHBOARD, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(DASHBOARD, { replace: true });
    }
  };

  useGetSettingDataAlways(
    ({ data: setting }) => {
      if (setting) {
        formik.values.home_page_general_email_logo = setting?.home_page_general_email_logo;
        formik.values.home_page_general_header_logo = setting?.home_page_general_header_logo;
        formik.values.home_page_general_login_logo = setting?.home_page_general_login_logo;
        formik.values.home_page_general_favicon_logo = setting?.home_page_general_favicon_logo;
        formik.values.home_page_general_seo_title = setting?.home_page_general_seo_title;
        formik.values.home_page_general_seo_description =
          setting?.home_page_general_seo_description;
        formik.values.home_page_general_email_address = setting?.home_page_general_email_address;
        formik.values.home_page_common_general_og_image =
          setting?.home_page_common_general_og_image;
      }
      dispatch(addSetting(setting));
    },
    (error) => {
      if (error.data.redirect === true) {
        toast.error(error.message);
        navigate(DASHBOARD);
      }
    }
  );

  /**
   * !This API will call when user click on Submit Button
   */
  const formik = useFormik({
    initialValues: {
      home_page_general_email_logo: getSettingData?.home_page_general_email_logo,
      home_page_general_header_logo: getSettingData?.home_page_general_header_logo,
      home_page_general_login_logo: getSettingData?.home_page_general_login_logo,
      home_page_general_favicon_logo: getSettingData?.home_page_general_favicon_logo,
      home_page_general_seo_title: getSettingData?.home_page_general_seo_title,
      home_page_general_seo_description: getSettingData?.home_page_general_seo_description,
      home_page_general_email_address: getSettingData?.home_page_general_email_address || '',
      home_page_common_general_og_image: getSettingData?.home_page_common_general_og_image || '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('values: ', values);
      doStoreSettingData(values);
    },
  });
  if (!getSettingData) {
    return <div>Loading...</div>; // Or some other loading indicator
  }
  return (
    <>
      <CommonHelmet t={t} title={t('page.general_setting_tab_general_setting')} />
      <NavItemsCommon navItemName={mailNotificationNavItems} t={t} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addGDError}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <h1 className="page-heading-center">{t('page.general_setting_header_text')}</h1>
        <div className="settings">
          <div className="general-setting">
            <Form onSubmit={formik.handleSubmit}>
              <Row className="p-4">
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_dashboard_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={dashboardRef}
                      name="home_page_general_header_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_header_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => dashboardRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.home_page_general_header_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_header_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_header_logo', null);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_header_logo &&
                    formik.errors.home_page_general_header_logo ? (
                      <div>{t(formik.errors.home_page_general_header_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_favicon_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={faviconRef}
                      name="home_page_general_favicon_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_favicon_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => faviconRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.home_page_general_favicon_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_favicon_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_favicon_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_favicon_logo &&
                    formik.errors.home_page_general_favicon_logo ? (
                      <div>{t(formik.errors.home_page_general_favicon_logo)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="p-4">
                <Col lg={6} md={6} sm={12}>
                  <Form.Group className="change-align">
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_login_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={loginRef}
                      name="home_page_general_login_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_login_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => loginRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image change-align">
                      {formik.values.home_page_general_login_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_login_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_login_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_login_logo &&
                    formik.errors.home_page_general_login_logo ? (
                      <div>{t(formik.errors.home_page_general_login_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_email_logo')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={emailRef}
                      name="home_page_general_email_logo"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_general_email_logo',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => emailRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.home_page_general_email_logo && (
                        <div>
                          <img
                            src={imagePreviewFormik(formik.values.home_page_general_email_logo)}
                            width="100px"
                            alt="profile_img"
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_general_email_logo', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_general_email_logo &&
                    formik.errors.home_page_general_email_logo ? (
                      <div>{t(formik.errors.home_page_general_email_logo)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.home_page_common_general_og_image')}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      hidden
                      ref={ogImageRef}
                      name="home_page_common_general_og_image"
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={(event) => {
                        formik.setFieldValue(
                          'home_page_common_general_og_image',
                          event.currentTarget.files[0]
                        );
                      }}
                    />
                    <Button
                      type="button"
                      className="upload-button"
                      onClick={() => ogImageRef.current?.click()}>
                      {t('page.file_upload')}
                    </Button>
                    <div className="preview-image">
                      {formik.values.home_page_common_general_og_image && (
                        <div>
                          <img
                            src={imagePreviewFormik(
                              formik.values.home_page_common_general_og_image
                            )}
                            width="100px"
                            alt=""
                          />
                          <FontAwesomeIcon
                            icon={faClose}
                            onClick={() => {
                              formik.setFieldValue('home_page_common_general_og_image', '');
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                  <div className="form-field-error-text">
                    {formik.touched.home_page_common_general_og_image &&
                    formik.errors.home_page_common_general_og_image ? (
                      <div>{t(formik.errors.home_page_common_general_og_image)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="p-4">
                <Col lg={12}>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_general_seo_title_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_seo_title &&
                        formik.errors.home_page_general_seo_title
                          ? 'form-field-error'
                          : formik.touched.home_page_general_seo_title &&
                              !formik.errors.home_page_general_seo_title
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="home_page_general_seo_title"
                      placeholder={t(
                        'page.general_setting_home_page_general_seo_title_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_page_general_seo_title',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_seo_title}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_seo_title &&
                      formik.errors.home_page_general_seo_title ? (
                        <div>{t(formik.errors.home_page_general_seo_title)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_home_page_general_seo_description_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_seo_description &&
                        formik.errors.home_page_general_seo_description
                          ? 'form-field-error'
                          : formik.touched.home_page_general_seo_description &&
                              !formik.errors.home_page_general_seo_description
                            ? 'form-field-success'
                            : '')
                      }
                      as="textarea"
                      rows={3}
                      type="text"
                      name="home_page_general_seo_description"
                      placeholder={t(
                        'page.general_setting_home_page_general_seo_description_placeholder'
                      )}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_page_general_seo_description',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_seo_description}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_seo_description &&
                      formik.errors.home_page_general_seo_description ? (
                        <div>{t(formik.errors.home_page_general_seo_description)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.general_setting_general_email_label')}
                    </Form.Label>
                    <Form.Control
                      className={
                        ' ' +
                        (formik.touched.home_page_general_email_address &&
                        formik.errors.home_page_general_email_address
                          ? 'form-field-error'
                          : formik.touched.home_page_general_email_address &&
                              !formik.errors.home_page_general_email_address
                            ? 'form-field-success'
                            : '')
                      }
                      type="text"
                      name="home_page_general_email_address"
                      placeholder={t('page.general_setting_general_email_placeholder')}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(
                          'home_page_general_email_address',
                          value.replace(/^\s+/g, '')
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.home_page_general_email_address}
                    />
                    <div className="form-field-error-text">
                      {formik.touched.home_page_general_email_address &&
                      formik.errors.home_page_general_email_address ? (
                        <div>{t(formik.errors.home_page_general_email_address)}</div>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton type="submit" isDirtyForm={formik.dirty ? 1 : 0}>
                    {' '}
                    {t('page.general_setting_save_button')}
                  </TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
GeneralSetting.propTypes = {
  t: PropTypes.func,
};
export default GeneralSetting;
