import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LIST, ADD, EDIT } from 'config';
const ListTeamMemberPage = React.lazy(() => import('./ListTeamMemberPage'));
const AddTeamMemberPage = React.lazy(() => import('./AddTeamMemberPage'));
const EditTeamMemberPage = React.lazy(() => import('./EditTeamMemberPage'));

const TeamMemberRoute = ({ t }) => {
  return (
    <>
      <Routes>
        <Route index exact path={LIST} element={<ListTeamMemberPage t={t} />} />
        <Route exact path={ADD} element={<AddTeamMemberPage t={t} />} />
        <Route exact path={EDIT + ':id'} element={<EditTeamMemberPage t={t} />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};
TeamMemberRoute.propTypes = {
  t: PropTypes.func,
};
export default TeamMemberRoute;
