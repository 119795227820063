import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ABOUT_SEO,
  ABOUT_WHO_WE_ARE,
  ABOUT_VISION,
  ABOUT_OUR_TEAM,
  ABOUT_MISSION,
  ABOUT_BANNER,
} from 'config';

const AboutSetting = React.lazy(() => import('./AboutSettingPage'));
const AboutSeoSetting = React.lazy(() => import('./AboutSeoSettingPage'));
const AboutWhoWeAreSetting = React.lazy(() => import('./AboutWhoWeAreSettingPage'));
const AboutOurMissionSetting = React.lazy(() => import('./AboutOurMissionSettingPage'));
const AboutOurVisionSetting = React.lazy(() => import('./AboutOurVisionSettingPage'));
const AboutOurTeamSetting = React.lazy(() => import('./AboutOurTeamSettingPage'));

const AboutRoutes = ({ t }) => (
  <>
    <Routes>
      <Route exact path={ABOUT_BANNER} element={<AboutSetting t={t} />} />
      <Route exact path={ABOUT_SEO} element={<AboutSeoSetting t={t} />} />
      <Route exact path={ABOUT_WHO_WE_ARE} element={<AboutWhoWeAreSetting t={t} />} />
      <Route exact path={ABOUT_MISSION} element={<AboutOurMissionSetting t={t} />} />
      <Route exact path={ABOUT_VISION} element={<AboutOurVisionSetting t={t} />} />
      <Route exact path={ABOUT_OUR_TEAM} element={<AboutOurTeamSetting t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  </>
);
AboutRoutes.propTypes = {
  t: PropTypes.func,
};
export default AboutRoutes;
