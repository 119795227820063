import client from 'libs/HttpClient';

class AdminDashboardService {
  static getDashboardData() {
    return client.get('dashboard/dashboard-counts');
  }
  static getUserData({ params }) {
    return client.get('dashboard/users-data', { params });
  }
}

export { AdminDashboardService };
