export const ADMIN = 'admin';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forget-password';
export const OTP_VERIFY = '/otp-verification';
export const RESET_PASSWORD = '/reset-password';
export const SET_PASSWORD = '/set-password';
export const DASHBOARD = '/dashboard';
export const EDIT_PROFILE = '/edit-profile';
export const CHANGE_PASSWORD = '/change-password';
export const CMS = '/cms';
export const EMAIL_TEMPLATE = '/email-template';
export const TRANSLATIONS = '/translations';
export const FAQ = '/faq';
export const FAQ_CATEGORY = '/faq-category';
export const USERS = '/users';
export const NOTIFICATIONS = '/notifications';
export const SETTING = '/setting';
export const FEATURE = '/feature';
export const TEAM_MEMBER = '/team-member';
export const ADD = '/add';
export const EDIT = '/edit/';
export const VIEW = '/view/';
export const LIST = '/list';
export const HOME = '/home';
export const ABOUT = '/about';
export const CONTACT = '/contact';
export const HOME_BANNER = '/home-banner';
export const HOME_JOB_POST = '/home-job-post';
export const HOME_DOWNLOAD = '/home-download';
export const HOME_FOOTER = '/home-footer';
export const HOME_SEO = '/home-seo';
export const ABOUT_BANNER = '/about-banner';
export const ABOUT_WHO_WE_ARE = '/about-who-we-are';
export const ABOUT_MISSION = '/about-our-mission';
export const ABOUT_VISION = '/about-our-vision';
export const ABOUT_OUR_TEAM = '/about-our-team';
export const ABOUT_SEO = '/about-seo';
export const GENERAL = '/general';
export const CONTACT_SETTING = '/contact-us-setting';
export const FAQ_SETTING = '/faq-setting';
export const MAIL_SETTING = '/email-and-notification';

export const SETTING_HOME = '/setting/home';
export const SETTING_ABOUT = '/setting/about';
export const SETTING_CONTACT_US = '/setting/contact';
export const SETTING_FAQ = '/setting/faq';
export const CATEGORIES = '/category';
export const ATTRIBUTES = '/attributes';
