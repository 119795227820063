import React, { useState } from 'react';
import { CommonHelmet } from 'common';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

import usersIcon from 'assets/images/dashboard_user_icon.svg';
import 'assets/scss/page/_dashboard.scss';
import { useDashboardCount, useUsersData } from 'hooks';
const customSelectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderRadius: '0.375rem',
    color: 'white',
    width: '100%',
    fontWeight: 400,
    fontSize: '15px',
    minWidth: '130px',
    background: 'transparent',
    minHeight: 45,
    paddingLeft: '0.5rem',
    boxShadow: 'none',
    border: '1px solid #ced4da',
  }),
  singleValue: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#ffffff',
    };
  },

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (styles) => ({
    ...styles,
    maxWidth: '100%',
    minWidth: '100px',
  }),
};

const DashboardPage = ({ t }) => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [userYear, setUserYear] = useState(new Date().getFullYear());
  const [userData, setUserData] = useState({});

  useDashboardCount((res) => {
    setDashboardData(res?.data);
  });
  const { refetch: getUserData } = useUsersData(
    { year: userYear },
    (res) => {
      if (res?.data) {
        const data = {
          labels: res?.data?.userData?.map((payment) => payment?.month),
          datasets: [
            {
              label: t('page.total_users_chart_title'),
              data: res?.data?.userData?.map((payment) => payment.totalUsers),
              borderColor: 'white',
              backgroundColor: 'white',
              fill: true,
              tension: 0.4,
            },
          ],
        };
        setUserData(data);
      }
    },
    (error) => {
      console.error('error', error);
    }
  );
  const iconMapping = {
    dashboard_users_icon: usersIcon,
  };
  const handleNavigation = (path) => {
    navigate(path);
  };

  const currentYear = new Date().getFullYear();
  const customOptions = {
    options: Array.from(
      { length: currentYear - (Number(dashboardData?.createdYear) - 1) },
      (_, index) => {
        const year = currentYear - index;
        return { value: String(year), label: String(year) };
      }
    ),
  };

  const usersOptions = customOptions?.options;
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          color: 'white',
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleColor: 'white',
        bodyColor: 'white',
      },
    },
    scales: {
      x: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
      y: {
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)',
        },
      },
    },
    elements: {
      line: {
        borderWidth: 3,
      },
      point: {
        radius: 5,
      },
    },
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  };
  return (
    <>
      <CommonHelmet t={t} title={t('page.dashboard_page')} />
      <h1>{t('page.dashboard_page')}</h1>
      {dashboardData && (
        <Row className="row-gap-5 p-5">
          {dashboardData &&
            dashboardData?.dashboardData?.map((item, index) => {
              const Icon = iconMapping[item.icon];
              return (
                <Col lg={4} md={6} key={index}>
                  <div className="data-card p-4" onClick={() => handleNavigation(item.navigate)}>
                    <div className="icon-box" style={{ backgroundColor: 'rgb(117, 50, 198)' }}>
                      <img src={Icon} className="card-img-top" alt="icon" />
                    </div>
                    <h5 className="data-title">{item.title}</h5>
                    <h2 className="data-value mt-2">{item.count}</h2>
                  </div>
                </Col>
              );
            })}
        </Row>
      )}
      {userData.labels && (
        <Row className="mt-4 px-md-4 justify-content-between">
          <div className="chart-wrap mt-3 p-3 p-md-5 pt-4">
            <span className="d-flex justify-content-between align-items-center">
              <h2>{t('page.total_users_char_title')}</h2>
              <Select
                styles={customSelectStyles}
                options={usersOptions}
                value={usersOptions.find((option) => option.value == userYear)}
                onChange={(selectedOption) => {
                  setUserYear(selectedOption.value);
                  getUserData();
                }}
              />
            </span>
            <div className="bar-div">
              {userData.labels && <Bar data={userData} options={chartOptions} />}
            </div>
          </div>
        </Row>
      )}
    </>
  );
};
DashboardPage.propTypes = {
  t: PropTypes.func,
};
export { DashboardPage };
