import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { useFormik } from 'formik';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import 'assets/scss/page/_general_setting.scss';
import { useStoreSettingData, useGetAllSettingData } from 'hooks';
// import { useGetAllSettingData} from 'hooks';
import { AlertCommon } from 'common/components/AlertCommon';
import CustomSwitch from './CustomSwitch';
import { TNButton } from 'common/components';
import { mailNotificationNavItems, DASHBOARD } from 'config';
import { NavItemsCommon, CommonHelmet } from 'common';

const GeneralMailNotificationsSettings = ({ t }) => {
  const navigate = useNavigate();
  const [getSettingData, setSettingData] = useState();
  const { refetch } = useGetAllSettingData((res) => {
    setSettingData(res.data);
  });

  /**
   * !This Api will call on click submit button
   */
  const {
    mutate: doStoreSettingData,
    error: addGDList,
    isError: addGDrror,
  } = useStoreSettingData((response) => {
    toast.success(response.message);
    refetch();
  });
  const allEmailOn = (fields) => fields.every((field) => getSettingData?.[field] === '2');
  const allNotifOn = (fields) => fields.every((field) => getSettingData?.[field] === '2');

  const mailFields = [
    'mail_notif_user_active_yourself_mail',
    'mail_notif_user_deactive_yourself_mail',
  ];

  const notifFields = [
    'mail_notif_user_active_yourself_notif',
    'mail_notif_user_deactive_yourself_notif',
  ];

  /**
   * !This API will call when user click on Submit Button
   */
  const formik = useFormik({
    initialValues: {
      allEmailTrigger: allEmailOn(mailFields) ? '2' : '1',
      allNotificationTrigger: allNotifOn(notifFields) ? '2' : '1',
      allSectionEmailTrigger: allEmailOn(mailFields) ? '2' : '1',
      allSectionNotificationTrigger: allNotifOn(notifFields) ? '2' : '1',
      mail_notif_user_active_yourself_mail: getSettingData?.mail_notif_user_active_yourself_mail,
      mail_notif_user_active_yourself_notif: getSettingData?.mail_notif_user_active_yourself_notif,
      mail_notif_user_deactive_yourself_mail:
        getSettingData?.mail_notif_user_deactive_yourself_mail,
      mail_notif_user_deactive_yourself_notif:
        getSettingData?.mail_notif_user_deactive_yourself_notif,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      doStoreSettingData(values);
    },
  });

  useEffect(() => {
    if (getSettingData) {
      formik.setValues({
        allEmailTrigger: allEmailOn(mailFields) ? '2' : '1',
        allNotificationTrigger: allNotifOn(notifFields) ? '2' : '1',
        allSectionEmailTrigger: allEmailOn(mailFields) ? '2' : '1',
        allSectionNotificationTrigger: allNotifOn(notifFields) ? '2' : '1',
        mail_notif_user_active_yourself_mail: getSettingData?.mail_notif_user_active_yourself_mail,
        mail_notif_user_active_yourself_notif:
          getSettingData?.mail_notif_user_active_yourself_notif,
        mail_notif_user_deactive_yourself_mail:
          getSettingData?.mail_notif_user_deactive_yourself_mail,
        mail_notif_user_deactive_yourself_notif:
          getSettingData?.mail_notif_user_deactive_yourself_notif,
      });
    }
  }, [getSettingData]);
  /**
   * !This block will call on click cancel
   */
  const handleCancel = () => {
    if (formik.dirty) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close p-0"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message'),
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/dashboard/`, { replace: true });
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        },
      });
    } else {
      navigate(DASHBOARD, { replace: true });
    }
  };
  const handleEmailToggleChange = (checked, mailField) => {
    const newValue = checked ? '2' : '1';
    formik.setFieldValue(mailField, newValue);
    if (mailField === 'allSectionEmailTrigger') {
      mailFields.forEach((field) => formik.setFieldValue(field, newValue));
    } else {
      // Manually construct the new form values for mail fields
      const updatedValues = {
        ...formik.values,
        [mailField]: newValue,
      };

      // Check if all mail fields are set to '2' with updated values
      const allEmailsOn = mailFields.every((field) => updatedValues[field] === '2');
      formik.setFieldValue('allEmailTrigger', allEmailsOn ? '2' : '1');
      formik.setFieldValue('allSectionEmailTrigger', allEmailsOn ? '2' : '1');
    }
  };

  const handleNotificationToggleChange = (checked, notifField) => {
    const newValue = checked ? '2' : '1';
    formik.setFieldValue(notifField, newValue);
    if (notifField === 'allSectionNotificationTrigger') {
      notifFields.forEach((field) => formik.setFieldValue(field, newValue));
    } else {
      const updatedValues = {
        ...formik.values,
        [notifField]: newValue,
      };

      // Check if all mail fields are set to '2' with updated values
      const allNotificationsOn = notifFields.every((field) => updatedValues[field] === '2');
      formik.setFieldValue('allNotificationTrigger', allNotificationsOn ? '2' : '1');
      formik.setFieldValue('allSectionNotificationTrigger', allNotificationsOn ? '2' : '1');
    }
  };

  return (
    <>
      <CommonHelmet t={t} title={t('page.general_setting_tab_email_and_notification_setting')} />
      <NavItemsCommon navItemName={mailNotificationNavItems} t={t} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addGDrror}>
        {addGDList}
      </AlertCommon>
      <Card className="inner-box">
        <h1 className="page-heading-center">
          {t('page.general_setting_tab_email_and_notification_setting')}
        </h1>
        <div className="settings w-100">
          <div className="general-setting overflow-hidden">
            <Form onSubmit={formik.handleSubmit}>
              <Row
                className="mt-4 action-title py-3 py-md-0"
                style={{ backgroundColor: '#F0F5F1' }}>
                <Col
                  xs={4}
                  lg={8}
                  className="px-4 py-2 d-flex align-items-center justify-content-between ">
                  {t('page.general_setting_notification_actions_label')}
                </Col>
                <Col
                  xs={4}
                  md={2}
                  lg={2}
                  className="d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap gap-2 mail-title-cls">
                  {t('page.general_setting_email_toggle_setting')}
                  <CustomSwitch
                    onChange={(checked) =>
                      handleEmailToggleChange(checked, 'allSectionEmailTrigger')
                    }
                    checked={formik.values.allSectionEmailTrigger === '2'}
                  />
                </Col>
                <Col
                  xs={4}
                  md={4}
                  lg={2}
                  className="d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap gap-2 mail-title-cls">
                  {t('page.general_setting_notification_toggle_setting')}
                  <CustomSwitch
                    onChange={(checked) =>
                      handleNotificationToggleChange(checked, 'allSectionNotificationTrigger')
                    }
                    checked={formik.values.allSectionNotificationTrigger === '2'}
                  />
                </Col>
              </Row>
              {[
                {
                  label: 'page.mail_notif_user_active_yourself_label',
                  mailField: 'mail_notif_user_active_yourself_mail',
                  notifField: 'mail_notif_user_active_yourself_notif',
                },
                {
                  label: 'page.mail_notif_user_deactive_yourself_label',
                  mailField: 'mail_notif_user_deactive_yourself_mail',
                  notifField: 'mail_notif_user_deactive_yourself_notif',
                },
              ].map(({ label, mailField, notifField }) => (
                <Row className="action-title-sub-row" key={label}>
                  <Col
                    xs={6}
                    lg={8}
                    className="px-4 py-2 d-flex align-items-center justify-content-between">
                    {t(label)}
                  </Col>
                  <Col
                    xs={3}
                    lg={2}
                    md={2}
                    className="d-flex align-items-center eml-cont-switch justify-content-center">
                    <CustomSwitch
                      checked={formik.values[mailField] === '2'}
                      onChange={(checked) => handleEmailToggleChange(checked, mailField)}
                    />
                  </Col>
                  <Col
                    xs={3}
                    lg={2}
                    md={4}
                    className="d-flex align-items-center justify-content-center">
                    <CustomSwitch
                      checked={formik.values[notifField] === '2'}
                      onChange={(checked) => handleNotificationToggleChange(checked, notifField)}
                    />
                  </Col>
                </Row>
              ))}
              <Row>
                <div className="primary-button">
                  <span className="link-center" onClick={handleCancel}>
                    {t('page.general_setting_cancel_link')}
                  </span>
                  <TNButton type="submit">{t('page.general_setting_save_button')}</TNButton>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </Card>
    </>
  );
};
GeneralMailNotificationsSettings.propTypes = {
  t: PropTypes.func,
};
export { GeneralMailNotificationsSettings };
