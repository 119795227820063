import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

const HomeBannerSetting = React.lazy(() => import('./HomeBannerSettingPage'));
const HomeSeoSetting = React.lazy(() => import('./HomeSeoSettingPage'));
const HomeJobPostSetting = React.lazy(() => import('./HomeJobPostSettingPage'));
const HomeFooterSetting = React.lazy(() => import('./HomeFooterSettingPage'));
const HomeDownloadSetting = React.lazy(() => import('./HomeDownloadSettingPage'));

const HomeRoutes = ({ t }) => (
  <>
    <Routes>
      <Route exact path="/home-banner" element={<HomeBannerSetting t={t} />} />
      <Route exact path="/home-seo" element={<HomeSeoSetting t={t} />} />
      <Route exact path="/home-job-post" element={<HomeJobPostSetting t={t} />} />
      <Route exact path="/home-footer" element={<HomeFooterSetting t={t} />} />
      <Route exact path="/home-download" element={<HomeDownloadSetting t={t} />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  </>
);
HomeRoutes.propTypes = {
  t: PropTypes.func,
};
export default HomeRoutes;
