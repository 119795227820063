import { createSlice } from '@reduxjs/toolkit';

/**
 * Slice for Loader
 */
const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    count: 0,
    fcmToken: '',
    notificationCount: 0,
  },
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      state.count = action?.payload?.length;
    },

    addNotification: (state, action) => {
      state.notifications.push(action.payload);
      state.count += 1;
    },
    storeToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
    incrementNotificationCount: (state) => {
      state.notificationCount += 1;
    },
    decrementNotificationCount: (state) => {
      state.notificationCount -= 1;
    },
  },
});
const {
  setNotifications,
  addNotification,
  storeToken,
  setNotificationCount,
  incrementNotificationCount,
  decrementNotificationCount,
} = notificationSlice.actions;
export {
  notificationSlice,
  setNotifications,
  addNotification,
  storeToken,
  setNotificationCount,
  incrementNotificationCount,
  decrementNotificationCount,
};
export default notificationSlice.reducer;
