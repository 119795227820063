import { TeamMemberService } from 'api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useListTeamMember = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ['team-member-list', [params]],
    () => TeamMemberService.listTeamMember({ params }),
    {
      onSuccess,
      keepPreviousData: true,
      onError,
    }
  );
};

const useAddTeamMember = (onSuccess, onError = onDefaultError) => {
  return useMutation(TeamMemberService.addTeamMember, {
    onSuccess,
    onError,
  });
};

const useViewTeamMember = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(['team-member-view', [params]], () => TeamMemberService.viewTeamMember(params), {
    onSuccess,
    onError,
  });
};
const useUpdateTeamMember = (onSuccess, onError = onDefaultError) => {
  return useMutation(TeamMemberService.updateTeamMember, {
    onSuccess,
    onError,
  });
};

const useDeleteTeamMember = (onSuccess, onError = onDefaultError) => {
  return useMutation(TeamMemberService.deleteTeamMember, {
    onSuccess,
    onError,
  });
};

export {
  useListTeamMember,
  useAddTeamMember,
  useViewTeamMember,
  useUpdateTeamMember,
  useDeleteTeamMember,
};
