import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const NavItemsCommon = ({ navItemName, t }) => {
  return (
    <Nav className="tab-navigation">
      {navItemName.map(({ path, translationKey }) => (
        <Nav.Item key={path}>
          <NavLink to={path}>{t(translationKey)}</NavLink>
        </Nav.Item>
      ))}
    </Nav>
  );
};
NavItemsCommon.propTypes = {
  t: PropTypes.func,
  navItemName: PropTypes.any,
};
export { NavItemsCommon };
